import { useEffect, useState, useContext } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { BookingContext } from "../../../contexts/bookingContext";
import { createPaymentIntent, getPaymentConfiguration } from "../../../services/payment-service";

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [options, setOptions] = useState(null); // Add a state for options
  let { bookingDetails, setBookingDetails } = useContext(BookingContext);

  useEffect(() => {

    const configureAndCreatePaymentIntent = async () => {
      if (bookingDetails.pageDisplay.currentTab === 4) {
        const publishableKey = await getPaymentConfiguration();
        setStripePromise(loadStripe(publishableKey));

        var { clientSecret, paymentIntentId } = await createPaymentIntent(bookingDetails.bookingFee);
        setClientSecret(clientSecret);
        bookingDetails.paymentDetails.paymentIntentId = paymentIntentId;
        setBookingDetails({ ...bookingDetails });
        setOptions({
          clientSecret,
        });

      }
    }
    configureAndCreatePaymentIntent();
    console.log('options', options);
  }, [bookingDetails.pageDisplay.currentTab]);


  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

export default Payment;